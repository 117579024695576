<template>
  <div class="main-container">
    <div class="main-header">
      <div class="logo-box">
        <img src="/images/logo.png" alt="">
      </div>
      <div class="name-box">韦氏儿童智力量表第四版中文版计分系统</div>
      <div class="activation-box" @click="goActivation">
        <img src="/images/activation-icon.png" alt="">
        <span>新主试激活</span>
      </div>
    </div>
    <div class="main-body">
      <div class="form-box">
        <div class="header">
          <div class="img"><img src="/images/small-teacher-img.png" alt=""></div>
          <div class="name">忘记密码</div>
          <div class="describe">(带 <span>*</span> 的为必填项)</div>
        </div>
        <div class="form-box-sh">

          <a-form-model ref="form" :model="formModel"  :rules="formRules" style="padding:40px 0 " autoComplete="off" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item label="证书编号" prop="certificate_no">
              <a-input allowClear placeholder="证书编号" class="cu-input" v-model="formModel.certificate_no"/>
            </a-form-model-item>
            <a-form-model-item label="主试姓名" prop="name">
              <a-input allowClear placeholder="主试姓名" class="cu-input"   v-model="formModel.name"/>
            </a-form-model-item>
            <a-form-model-item label="身份证号码" prop="id_card_no">
              <a-input allowClear placeholder="身份证号码" class="cu-input" v-model="formModel.id_card_no"/>
            </a-form-model-item>
            <a-form-model-item label="邮箱" prop="email">
              <a-input allowClear placeholder="邮箱" class="cu-input" v-model="formModel.email"/>
            </a-form-model-item>
            <a-form-model-item label="手机号码" prop="mobile">
              <a-input allowClear placeholder="手机号码" class="cu-input" v-model="formModel.mobile"/>
            </a-form-model-item>
            <a-form-model-item label="新密码" prop="pwd">
              <a-input allowClear type="password" placeholder="新密码" class="cu-input" v-model="formModel.pwd"/>
            </a-form-model-item>
            <a-form-model-item label="重输新密码" prop="pwd1">
              <a-input allowClear type="password" placeholder="重输新密码" class="cu-input" v-model="formModel.pwd1"/>
            </a-form-model-item>
            <a-form-model-item label="账号" prop="account">
              <a-input allowClear placeholder="账号" class="cu-input" v-model="formModel.account"/>
            </a-form-model-item>
            <a-form-model-item label="密码找回问题" prop="prompt_questions">
              <a-input allowClear placeholder="密码找回问题" class="cu-input" v-model="formModel.prompt_questions"/>
            </a-form-model-item>
            <a-form-model-item label="密码找回答案" prop="prompt_answer">
              <a-input allowClear placeholder="密码找回答案" class="cu-input" v-model="formModel.prompt_answer"/>
            </a-form-model-item>
            <a-form-model-item label="验证码" prop="verification_code">
              <div style="display: flex">
                <a-input allowClear placeholder="验证码" class="cu-input" v-model="formModel.verification_code"/>
                <img @click="refreshCode" :src="codeImgUrl" alt="" style="margin-left: 20px;cursor: pointer">
              </div>
            </a-form-model-item>
            <a-form-model-item label="" :wrapper-col="{span:12,offset:6}">
              <div class="btn" style="cursor: pointer" @click="forgetPwd">登录</div>
            </a-form-model-item>
            <div class="line" style="border-bottom:1px solid  #eeeeee;margin: 10px 0"></div>
            <div class="de-box">
              <img src="/images/de-icon.png" alt="" class="de-img">
              <div class="de-content">
                <span class="de">如果您希望通过客服人员修复密码，请您和京美客户服务中心联系：</span>
                <span class="de">密码重设专线：0756-3396889</span>
                <span class="ac">温馨提醒:要通过密码提示问题重设密码,请确保您的电子邮箱之前已经正确填写！</span>
              </div>
            </div>
          </a-form-model>

        </div>
      </div>
    </div>
    <footer-bottom></footer-bottom>
  </div>
</template>

<script>
import * as Api from './api';
import * as commonApi from '@/commonApi';
import { uuid } from 'vue-uuid';
import {message} from "ant-design-vue";
import {ForgetPwd} from "./api";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.formModel.newPwd != this.formModel.newPwd1) {
        callback(new Error('密码输入不一致'));
      }
      callback();
    };
    var validateMail = (rule, value, callback) => {
      if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        callback(new Error('请输入正确的邮箱'));
      }
      callback();
    };
    return {
      codeImgUrl: '',
      labelCol: {span: 6},
      wrapperCol: {span: 12},
      formModel: {
        uuid: '',
        certificate_no:"",
        name:"",
        id_card_no:"",
        email:"",
        mobile:"",
        account:"",
        prompt_questions:'',
        prompt_answer:"",
        pwd: '',
        pwd1: '',
        verification_code: ''
      },
      formRules: {
        certificate_no: [
          { required: true, message: '请输入证书编号', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入主试姓名', trigger: 'change' },
        ],
        id_card_no: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'change' },
          { validator: validateMail, trigger: 'change' },
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'change' },
        ],
        pwd: [
          { required: true, message: '请输入新密码', trigger: 'change' },
        ],
        pwd1: [
          { required: true, message: '请再次输入新密码', trigger: 'change' },
          { validator: validatePass, trigger: 'change' },
        ],
        account: [
          { required: true, message: '请输入账号', trigger: 'change' },
        ],
        prompt_questions: [
          { required: true, message: '请输入密码找回问题', trigger: 'change' },
        ],
        prompt_answer: [
          { required: true, message: '请输入密码找回答案', trigger: 'change' },
        ],
        verification_code: [
          { required: true, message: '请输入验证码', trigger: 'change' },
        ]
      },
    };
  },
  mounted() {
    this.formModel.uuid = uuid.v1();
    this.codeImgUrl = process.env.VUE_APP_API + '/adapt/login/getVerifyCode?uuid=' + this.formModel.uuid
  },
  methods: {
    async forgetPwd() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          const res = await Api.ForgetPwd(this.formModel);
          if (res && res.code == 0) {
            message.success({ content: res.message});
            setTimeout(()=> {
              this.$router.push({ path: "/login" });
            }, 1500);
          }
        }
      });
    },
    goActivation() {
      this.$router.push({path: '/activation'})
    },
    refreshCode() {
      this.formModel.uuid = uuid.v1();
      this.codeImgUrl = process.env.VUE_APP_API + '/adapt/login/getVerifyCode?uuid=' + this.formModel.uuid;
    }
  }
};
</script>

<style scoped>
.de-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.de-box .de-img {
  width: 50px;
  height: 50px;

}

.de-box .de-content {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.de-box .de-content .de {
  font-size: 12px;
  font-weight: 400;
  color: #7E8589;
  line-height: 24px;
}

.de-box .de-content .ac {
  font-size: 12px;
  font-weight: 400;
  color: #D47548;
  line-height: 24px;
}

.main-body {
  background-image: url("/images/forget-password-bg.png");
  align-items: flex-start;
  justify-content: center;
}

.form-box {
  width: 900px;

}

.form-box-sh {
  background: #FFFFFF;
  box-shadow: 0px 23px 25px 0px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  position: relative;
  z-index: 9;
  margin-top: -10px;
  border-top-right-radius: 0
}

.form-box .header {
  display: flex;
  margin: 40px 0 0 0;
  position: relative;
  width: 100%;
  background-image: none;
}
.form-box .header .img, .form-box .header .name, .form-box .header .describe {
  flex: 1;
}
.form-box .header .img img {
  position: absolute;
  z-index: 1;
  width: 180px;
  top: -50px;
  left: 40px;
}
.form-box .header .name {
  text-align: center;
}
.form-box .header .describe {
  text-align: right;
}

.form-box .header .name {
  font-size: 28px;
  font-weight: 600;
  color: #832B75;
  line-height: 40px;
  text-shadow: 0px 0px 4px #FFFFFF;
  flex: 1;
}

.form-box .header .describe {
  font-size: 14px;
  font-weight: 400;
  color: #B6BDC1;
  line-height: 40px;
  text-shadow: 0px 0px 4px #FFFFFF;
  flex: 1;
}

.form-box .header .describe span {
  color: #832B75;
}

.btn {
  background: #832C75;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
}

</style>
